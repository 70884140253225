import {
  Paragraph,
  ColumnsContainer,
  Caption,
  Header,
  Column,
  OuterContainer
} from "./styles";
import styled from "styled-components";
import imageCharSheet from "./images/character-sheet-thumbnail.png";
import imageDatabase from "./images/component-database-thumbnail.png";
import imageBuilder from "./images/character-builder-thumbnail.png";
import imageQuickRef from "./images/quick-ref-thumbnail.png";
import charSheet from "./sentients-character-sheet-v0.88.pdf";
import quickRef from "./sentients-quick-reference-v2.0.pdf";
import { Link } from "react-router-dom";

const Image = styled.img`
`;

const ALink = styled.a`
  color: inherit;
`;

const StyledLink = styled(Link)`
  color: inherit;
`;

export default function Resources(){
  return (
    <OuterContainer>
      <ColumnsContainer>
        <Column>
          <Paragraph>
            <Header>CHARACTER BUILDER</Header>
            Check out the interactive <strong><ALink href="https://builder.sentientsrpg.com/" target="_blank">Character Builder</ALink></strong> web app. Requires a free account.
            <br/><br/>
            <a href="https://builder.sentientsrpg.com" target="_blank">
              <Image src={imageBuilder} alt="Sentients Character Builder Screenshot" />
            </a>
          </Paragraph>
          <br/><br/>
          <Paragraph>
            <Header>CHARACTER SHEET</Header>
            The PDF <strong><ALink href={charSheet} target="_blank">Sentients Character Sheet</ALink></strong>.
            <br/><br/>
            <a href={charSheet} target="_blank">
              <Image src={imageCharSheet} alt="Sentients Character Sheet" />
            </a>
          </Paragraph>
        </Column>
        <Column>
          <Paragraph>
            <Header>COMPONENT DATABASE</Header>

            Use the <strong>
              <ALink href="https://components.sentientsrpg.com/" target="_blank">
                Component Database
              </ALink></strong> to easily search and browse anthroid components.
            <br/><br/>
            <a href="https://components.sentientsrpg.com/" target="_blank">
              <Image src={imageDatabase} alt="Sentients Interactive Component Database" />
            </a>
          </Paragraph>
          <br/><br/>
          <Paragraph>
            <Header>QUICK REFERENCE</Header>
            The <strong><ALink href={quickRef} target="_blank">Quick Reference Sheet</ALink></strong> provides tons of useful gameplay information at a glance.
            <br/><br/>
            <a href={quickRef} target="_blank">
              <Image src={imageQuickRef} alt="Sentients Quick Reference Sheet" />
            </a>
          </Paragraph>
        </Column>
      </ColumnsContainer>
      <Caption>GAME DESIGN BY TED HAYES</Caption>
      <br /><br />
    </OuterContainer>
  )
};