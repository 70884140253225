import styled from 'styled-components';
import { useState } from 'react';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: right;
`;

const Image = styled.img`
  max-width: 458px;
  max-height: 623px;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

const Dot = styled.div`
  height: 7px;
  width: 7px;
  border-radius: 7px;
  border: 2px gray solid;
  margin: 10px 3px 0px;

  background: ${props => props.selected ? "gray" : "none"};
`;

const DotContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Caption = styled.span`
  margin: 25px 0px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
`;

export const Gallery = ({images}) => {
  const [currentImage, setCurrentImage] = useState(0);

  const imageClick = e => {
    setCurrentImage((currentImage + 1) % images.length);
  }

  const dots=images.map((image, i) => {
    return (<Dot key={i} selected={i === currentImage} />)
  })

  return (
    <Container>
      <Image onClick={imageClick} src={images[currentImage].src} alt={images[currentImage].alt} />
      <DotContainer>
        {dots}
      </DotContainer>
      <Caption>{images[currentImage].caption}</Caption>
    </Container>
  )
}