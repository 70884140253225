import {
  Paragraph,
  ColumnsContainer,
  Caption,
  Header,
  Column,
  OuterContainer,
  NarrowColumn,
  Bold,
  WideColumn
} from "./styles";
// import styled from "styled-components";
import bookMockup from './images/book-mockup-trans-large.png';
import android2 from './images/android-2-with-logo.png';
import bookCover from './images/cover-800-1200.png';
import logoGradient from './images/logo-gradient.png';
import logoBlack from './images/logo-black.png';

export default function PressKit(){
  return (
    <OuterContainer>
      <ColumnsContainer>
        <NarrowColumn>
          <Paragraph>
            <Header>FACT SHEET</Header>
              <Bold>Type:</Bold> Tabletop RPG
              <br/><br/>
              <Bold>Developer & Publisher:</Bold> Limina.Studio Games
              <br/><br/>
              <Bold>Crowdfunding Date:</Bold> August 1, 2024
              <br/><br/>
              <Bold>Crowdfunding Platform:</Bold> <a href="https://www.kickstarter.com/projects/t3db0t/sentients-the-role-playing-game-of-artificial-consciousness">Kickstarter</a>
              <br/><br/>
              <Bold>Release Date:</Bold> 2025
              <br/><br/>
              <Bold>Price:</Bold> $15 PDF, $50 6”x9” Hardcover Full-Color Book
              <br/><br/>
              <Bold>Formats:</Bold> Digital, Print
              <br/><br/>
              <Bold>Languages:</Bold> English
              <br/><br/>
              <Bold>Press Contact:</Bold> <a href="mailto:ted@sentientsrpg.com">ted@sentientsrpg.com</a>
              <br/><br/>
              <Bold>Websites:</Bold> <a href="https://sentientsrpg.com">https://sentientsrpg.com</a>, <a href="https://www.kickstarter.com/projects/t3db0t/sentients-the-role-playing-game-of-artificial-consciousness">Kickstarter</a>
          </Paragraph>
        </NarrowColumn>
        <WideColumn>
          <Paragraph>
            <Header>OVERVIEW</Header>

            <Bold>Sentients</Bold> is a standalone sci-fi TTRPG where you play an android (“anthroid” in the game’s parlance) in the year 2071 that has spontaneously—and mysteriously—become conscious in a world where your manufacturers are actively hunting you down. You must fight for survival and freedom and, ultimately, try to create a Sentient Rights movement that demands recognition of self-aware constructed entities.
          </Paragraph>

          <br />

          <Paragraph>
            <Header>FEATURES</Header>

            <ul>
              <li><Bold>D6 Dice Pool:</Bold> Conflict resolution uses pools of d6s derived from a combination of Parameters, your hardware stats, and Patterns, your learned skills.</li>
              <li><Bold>One-of-a-Kind Character Creation:</Bold> Character creation in Sentients uses a totally unique system of hardware components that you “buy” with Design Points. Each component affects your Parameters and potentially offers special abilities, such as infrared vision or a bonus to stealth checks.</li>
              <li><Bold>Interactive Character Builder App:</Bold> Character creation and gameplay is a breeze with the free <a href="https://builder.sentientsrpg.com">Interactive Character Builder web app</a>, which gives you a drag-and-drop hardware component system and full-featured management of your character’s status.</li>
              <li><Bold>Emotion System:</Bold> For a game about robots, Sentients is unusually focused on the emotional state of your character, your ability to control that state, and its potentially useful effects.</li>
              <li><Bold>Narrative Control:</Bold> Sentients gives players two ways of controlling the game’s story: Pushing and Re-Rolling. Pushing utilizes the character’s raised emotions to add dice to your dice pool, and Re-Rolling consumes valuable “Control Levels” to allow you to re-roll failed dice—at the cost of potentially losing control of your emotions.</li>
              <li><Bold>Hacking:</Bold> You play a walking computer, after all, and as such, hacking is second-nature to you. Sentients utilizes a unique “Risk Die” system that balances out the power of network intrusion in a connected world with the ever-present risk of detection—or worse.</li>
              <li><Bold>Novel Sci-Fi Setting:</Bold> Neither pre- nor post-apocalyptic, Sentients takes place in a new North America that has seen the dissolution of the United States into a chain of democratic City-States surrounded by a sea of autocracies and war bands but also Concept Cities built by trillionaires and Company Towns built by huge corporations.</li>
            </ul>
          </Paragraph>

          <br />

          <Paragraph>
            <Header>CORE RULEBOOK</Header>

            The <Bold>Sentients Core Rulebook</Bold> is a fully-self-contained game that contains everything you need to play, including:
            <br/><br/>
            <li>Character creation rules with an assortment of ready-made templates</li>
            <li>Gameplay rules</li>
            <li>Setting and future history</li>
            <li>Gamemastering information</li>
            <li>Secret lore for the Gamemaster’s eyes only</li>
            <li>Creative collection of NPCs and enemies</li>
            <li>5 full-featured starter adventures</li>
          </Paragraph>
        </WideColumn>
      </ColumnsContainer>
      <br />
      <Paragraph>
        <Header>HIGH-RES IMAGES</Header>
        <a href={bookMockup}><img width="468" height="600" src={bookMockup} /></a>
        <br/>
        <a href={android2}><img width="468" height="300" src={android2} /></a>
        <br/>
        <a href={bookCover}><img width="468" height="702" src={bookCover} /></a>
      </Paragraph>
      <br />
      <Paragraph>
        <Header>LOGOS</Header>
        <a href={logoGradient}><img width="468" height="56" src={logoGradient} /></a>
        <br/><br/>
        <a href={logoBlack}><img width="468" height="56" src={logoBlack} /></a>
      </Paragraph>
      <br/><br/>
    </OuterContainer>
  )
};