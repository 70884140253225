import styled from "styled-components";

const breakpoint = 940;

export const FlexContainer = styled.div`
  display: flex;
  margin-top: 30px;

  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
  }
`;

export const ColumnsContainer = styled.div`
  margin: 30px 10px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  @media (max-width: ${breakpoint}px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  max-width: 458px;
  margin-right: 15px;
  text-align: left;
`;

export const NarrowColumn = styled.div`
  max-width: 300px;
  margin-right: 15px;
  text-align: left;
`;

export const WideColumn = styled.div`
  max-width: 600px;
  /* margin-right: 15px; */
  text-align: left;
`;

export const OuterContainer = styled.div`
  text-align: center;
`;

export const Paragraph = styled.div`
  border-left: 4px #A7A7A7 solid;
  max-width: 405px;
  padding-left: 14px;
  margin: 0px;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  text-align: left;
`;

export const Caption = styled.span`
  margin: 25px 0px;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 12px;
  text-align: center;
`;

export const Header = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18px;
  margin-bottom: 15px;
  font-weight: bold;
`;

export const Bold = styled.span`
  /* margin: 25px 0px; */
  /* font-family: 'Josefin Sans', sans-serif; */
  /* font-size: 12px; */
  /* text-align: center; */
  font-weight: bold;
`;