import styled from 'styled-components';
import { useState } from 'react';

const EmailInput = styled.input`
  height: 2em;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2em;
  width: 65%;
  margin-top: 14px;
  border: 1px solid #979797;
  border-radius: 12px;
  padding: 0px 10px;
`;

const Button = styled.button`
  font-family: 'Open Sans', sans-serif;
  height: 2em;
  font-size: 1.2em;
  border: none;
  border-radius: 12px;
  margin-left: 10px;
  padding: 0px 10px;
  cursor: pointer;
`;

const Checkbox = styled.input`
  width: 1.5em;
  margin: 5px 7px 0px 0px;
  height: 1.5em;
  vertical-align: bottom;
`;

const ThanksMessage = styled.div`
  margin-top: 20px;
  font-size: 1.5em;
`;

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export const ContactForm = (props) => {
  const [email, setEmail] = useState("");
  const [playtest, setPlaytest] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "signup", "email": email, "playtest":playtest })
    })
      .then(() => setSuccess(true))
      .catch(error => setError(true));

    e.preventDefault();
  };

  const handleEmailChange = e => setEmail(e.target.value);

  const handleCheckboxChange = e => setPlaytest(e.target.checked);

  return (
    <div>
      {success
        ? <ThanksMessage>Thanks for signing up!</ThanksMessage>
        : <form onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="signup" />
            <label><Checkbox type="checkbox" name="playtest" onChange={handleCheckboxChange}></Checkbox>I'm interested in playtesting</label>
            <EmailInput required onChange={handleEmailChange} type="email" placeholder="email@address.com" id="news-email" name="email"></EmailInput>
            <Button type="submit">Sign Up</Button>
          </form>
      }
    </div>
  )
}