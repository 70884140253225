import {
  Paragraph,
  ColumnsContainer,
  Caption,
  Header,
  Column,
  OuterContainer
} from "./styles";
import styled from "styled-components";

export default function Game(){
  return (
    <OuterContainer>
      <ColumnsContainer>
        <Column>
          <Paragraph>
            <Header>CHARACTER CREATION</Header>

            Sentients characters are designed with a point-buy system of robotic components: everything from silent servos and advanced artificial cortices to biomimetic legs and plasma cutter arms. Components have ratings in different Parameters, such as “Neural Complexity,” “Sensorium,” “Canniness,” “Power” and “Mobility.” Levels in these Parameters are used in dice checks along with “Patterns,” or learned skills.
            <br /><br />
            Design your own with the drag-and-drop Character Builder web app, or choose from an “off-the-shelf” template and invent your backstory with the mini-lifepath feature.
          </Paragraph>

          <br /><br />

          <Paragraph>
            <Header>DICE MECHANICS</Header>

            <strong>Sentients</strong> uses a d6 dice pool system where players count “Marks”: 4s and 5s are 1 Mark, 6s are 2 Marks. The gamemaster, or Guide, sets difficulty levels in terms of these Marks. Dice pools are created by adding Parameters (derived from your hardware components) and Patterns (learnable skills).
          </Paragraph>
          
          <br /><br />

          <Paragraph>
            <Header>EMOTION &amp; STRESS</Header>

            For a game about robots, emotion and stress play an unusually important role. Emotion Levels are accrued that represent the intensity of Joy, Pain, Anger and Fear that you are experiencing&mdash;and these levels allow you to "Push" rolls, using your emotional state to push yourself to greater heights than normally possible. However, Pushing rolls also incurs Stress, which makes it harder and harder to control your emotional state.
          </Paragraph>
          <br /><br />
        </Column>
        <Column>
          <Paragraph>
            <Header>PUSHING ROLLS &amp; RE-ROLLING</Header>

            Adding dice to your dice pool via "Pushing" increases your chances of success, but every time you do it, you risk losing control of your emotions, which can temporarily take you out of the action and incurs even more Stress. You can also spend "Control Points" to re-roll critical Checks, but lowering your Control levels makes it harder to stay in command. Pushing and re-rolling offer the player multiple ways to change the narrative.
          </Paragraph>

          <br /><br />

          <Paragraph>
            <Header>HACKING</Header>

            You play a walking computer, and it’s only natural you would want to flex your abilities with virtual breaking and entering. <strong>Sentients</strong> uses a unique “Risk Die" mechanic that blends speed and simplicity with texture and danger&mdash;and you can even hack into other Sentients, potentially implanting new "Imprints" that they must then follow.
          </Paragraph>

          <br /><br />

          <Paragraph>
            <Header>PROCESSES</Header>

            Harness your anthroid nature with Processes, programs that grant you special powers in exchange for some of your electrical charge: “Self-Diagnostic,” “Stress Analysis,” “Cloud Insight” and many more.
          </Paragraph>

          <br /><br />

          <Paragraph>
            <Header>COMBAT</Header>

            Sentients are in constant danger, and it’s only a matter of time until you find yourself in a physical altercation. Wield a variety of powerful weapons, weapons that damage the Charge level of other anthroids and EMPs, and protect yourself with defensive upgrades such as Active Armor and EMF jammers.
          </Paragraph>

          <br /><br />

          <Paragraph>
            <Header>MUCH MORE</Header>

            All this and much more in the <strong>Sentients Core Rulebook,</strong> launching on Kickstarter soon!
          </Paragraph>
        </Column>
      </ColumnsContainer>
      <Caption>GAME DESIGN BY TED HAYES</Caption>
      <br /><br />
    </OuterContainer>
  )
};