import logo from './sentients-logo.svg';
import ksbanner from './images/kickstarter-banner.png'
import bookMockup from './images/book-mockup-trans-1.png'
import styled from 'styled-components';

import TopNav from './TopNav';
import { Outlet } from 'react-router-dom';

const Page = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
`;

const Logo = styled.img`
  width: 100%;
  max-width: 905px;
`;

const Header = styled.div`
  font-family: 'Josefin Sans', sans-serif;
  font-size: 18.7px;
  letter-spacing: 0.355em;
  margin-top: 20px;
  text-align: center;
`;

const KSBanner = styled.img`
  width: 100%;
  max-width: 600px;
`;

const BookMockup = styled.img`
  width: 100%;
  max-width: 400px;
`;

function App() {
  return (
    <Page>
      <Logo src={logo} alt="logo" />
      <Header>
        THE ROLE-PLAYING GAME OF ARTIFICIAL CONSCIOUSNESS
      </Header>
      
      <br/>

      <a href="https://www.kickstarter.com/projects/t3db0t/sentients-the-role-playing-game-of-artificial-consciousness" target="_blank">
        <BookMockup src={bookMockup} alt="Check it out on Kickstarter" />
      </a>

      

      <a href="https://www.kickstarter.com/projects/t3db0t/sentients-the-role-playing-game-of-artificial-consciousness" target="_blank">
        <KSBanner src={ksbanner} alt="Check it out on Kickstarter" />
      </a>

      <TopNav />

      <Outlet />
      
    </Page>
  );
}

export default App;
