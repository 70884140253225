import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-family: 'Josefin Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.2em;
  margin-top: 30px;
  text-align: center;
`;

const StyledNavLink = styled(NavLink)`
  font-weight: light;
  text-decoration: none;
  color: #4a4a4a;

  &[aria-current] {
    font-weight: bold;
  }
`;

export default function TopNav(){
  return (
    <LinksContainer>
      <StyledNavLink to="/">OVERVIEW</StyledNavLink>&nbsp;&nbsp;&nbsp;&nbsp;
      <StyledNavLink to="/game">THE GAME</StyledNavLink>&nbsp;&nbsp;&nbsp;&nbsp;
      <StyledNavLink to="/resources">RESOURCES</StyledNavLink>&nbsp;&nbsp;&nbsp;&nbsp;
      <StyledNavLink to="/presskit">PRESS KIT</StyledNavLink>
    </LinksContainer>
  );
}